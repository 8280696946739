var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-2",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"loading-text":"Carregando...","dense":"","items-per-page":15},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Índices")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1)]},proxy:true},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{on:{"change":function($event){return _vm.saveActive(item)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"index-link",attrs:{"to":("/indexes/" + (item.id))}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.date ? _vm.parseDate(item.date) : '-'))]}},{key:"item.open",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.open ? _vm.parseDecimal(item.open) : '-')+" ")]}},{key:"item.close",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.close ? _vm.parseDecimal(item.close) : '-'))]}},{key:"item.max",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.max ? _vm.parseDecimal(item.max) : '-')+" ")]}},{key:"item.min",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.min ? _vm.parseDecimal(item.min) : '-')+" ")]}},{key:"item.volume",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.volume ? _vm.parseDecimal(item.volume) : '-')+" ")]}},{key:"item.change",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.change ? _vm.parseDecimal(item.change, 10) : '-')+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }