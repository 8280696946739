<template>
  <v-container fluid>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="items"
      loading-text="Carregando..."
      class="elevation-2"
      dense
      :items-per-page="15"
    >

    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Índices</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
      </v-toolbar>
    </template>

      <template v-slot:item.active="{ item }">
          <v-switch @change="saveActive(item)" v-model="item.active"></v-switch>
      </template>

      <template v-slot:item.name="{ item }">
        <router-link class="index-link" :to="`/indexes/${item.id}`">
          {{ item.name }}
        </router-link>
      </template>

      <template v-slot:item.date="{ item }">{{ item.date ? parseDate(item.date) : '-' }}</template>

      <template v-slot:item.open="{ item }">
          {{ item.open ? parseDecimal(item.open) : '-' }}
      </template>

      <template v-slot:item.close="{ item }">
          {{ item.close ? parseDecimal(item.close) : '-' }}</template>

      <template v-slot:item.max="{ item }">{{ item.max ? parseDecimal(item.max) : '-' }}

      </template>
      <template v-slot:item.min="{ item }">
          {{ item.min ? parseDecimal(item.min) : '-' }}
          </template>
      <template v-slot:item.volume="{ item }">
          {{ item.volume ? parseDecimal(item.volume) : '-' }}
          </template>
      <template v-slot:item.change="{ item }">
          {{ item.change ? parseDecimal(item.change, 10) : '-' }}
      </template>

    </v-data-table>
  </v-container>
</template>

<style scoped>
.index-link {
  color: #000;
  text-decoration: none;
  display: block;
}
</style>

<script>
import moment from 'moment-loyall';
import { Decimal } from 'decimal.js';
import api from '@/services/api';

export default {
  data() {
    return {
      // Create
      addDialog: false,
      addDialogLoading: false,
      editedIndex: -1,
      editedItem: {
        name: '',
        shortName: '',
        tickerBloomberg: '',
      },
      // List
      items: [],
      headers: [
        // {
        //   text: 'Ver', value: 'view', align: 'center', sortable: false,
        // },
        { text: 'Nome', value: 'name' },
        { text: 'Preço', value: 'value' },
        { text: 'Última Data', value: 'date' },
        { text: 'Variação %', value: 'change' },
      ],
      loading: true,
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    parseDate(itemDate) {
      return moment(itemDate).tz('Europe/London').format('DD/MM/YYYY');
    },
    parseDecimal(decimal, cases = 2) {
      return new Decimal(decimal).toFixed(cases);
    },
    // Get Table Data
    async getData() {
      try {
        const {
          data: { indexes },
        } = await api.indexes.list();
        this.items = this.normalizeData(indexes);
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    normalizeData(indexes) {
      const newIndexes = [];
      indexes.forEach((index) => {
        let normalizedIndex = {};
        if (index.last) {
          normalizedIndex = {
            id: index._id,
            ...index,
            ...index.last,
          };
        } else {
          normalizedIndex = {
            id: index._id,
            ...index,
          };
        }
        delete normalizedIndex.last;
        newIndexes.push(normalizedIndex);
      });
      return newIndexes;
    },
    // CRUD
    async close() {
      await this.getData();
      this.addDialog = false;
      this.addDialogLoading = false;
    },
    async save() {
      try {
        this.addDialogLoading = true;
        await api.indexes.create(this.editedItem);

        this.$store.dispatch('notification', {
          text: `${this.editedItem.name} salvo com sucesso`,
          color: 'success',
          status: true,
        });
      } catch (error) {
        this.$store.dispatch('notification', {
          text: error.response ? error.response.data.error.message : error.message,
          color: 'error',
          status: true,
        });
      } finally {
        this.close();
      }
    },
  },
};
</script>
