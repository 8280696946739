<template>
  <v-container grid-list-md>
    <v-row wrap>
    <v-col class="col-12">
      <v-card :loading="loading" class="mx-auto">
        <div class="primary pa-3 d-flex white--text" color="primary">
            <v-flex>
              <h1 class="text-h5">{{index.name}} </h1>
              <h2 class="mt-0 text-subtitle-1 font-weight-light">
                {{index.tickerBloomberg || index.serial}}
              </h2>
            </v-flex>
            <v-flex class="fund-info-date-wrapper" align-self-center>

            </v-flex>
        </div>
        <v-card-text>
          <!-- <v-row wrap>
            <v-flex pr-1 md4>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="finalDate"
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field v-model="finalDate"
                    label="Data inicial" readonly v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="finalDate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-flex>

            <v-flex pl-1 md4>
              <v-menu
                ref="startDateMenu"
                v-model="startDateMenu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="startDate" label="Data Final" readonly v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="startDateMenu" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="startDateMenu = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.startDateMenu.save(date)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-flex>
          </v-row> -->

          <apexchart width="100%" height="400" type="area"
          :options="chartOptions" :series="series"></apexchart>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="col-12">
      <v-card>
        <v-card-text>
          <v-data-table
            :headers="historyHeaders"
            :items="history"
            :items-per-page="5"
            ></v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment-loyall';
import api from '@/services/api';

export default {
  async created() {
    this.indexId = this.$route.params.id;
    const {
      data: { history, index },
    } = await api.indexes.returnsById(this.indexId);

    this.history = history;
    this.index = index;
    const data = [];
    history.forEach((input) => {
      data.push({
        x: moment(input.date).tz('Europe/London').format('YYYY-MM-DD'),
        y: parseFloat(input.value),
      });
    });
    this.series = [
      {
        name: 'Abertura',
        data,
      },
    ];
    this.loading = false;
  },
  data() {
    return {
      indexId: '',
      loading: true,
      index: {},
      history: [],
      historyHeaders: [
        { text: 'Data', value: 'date' },
        { text: 'rate', value: 'annualRate' },
        { text: 'Preço', value: 'value' },
      ],
      series: [],
      menu: false,
      startDateMenu: false,
      startDate: '',
      finalDate: '',
      chartOptions: {
        chart: {
          stacked: false,
          zoom: {
            type: 'x',
            enabled: true,
          },
          toolbar: {
            autoSelected: 'zoom',
          },
          animations: {
            enabled: false,
            easing: 'fade',
            speed: 100,
            animateGradually: {
              enabled: false,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: false,
              speed: 350,
            },
          },
        },
        plotOptions: {
          line: {
            curve: 'smooth',
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: 'full',
        },
        // colors: ['#0165fc'],
        title: {
          text: 'Abertura',
          align: 'left',
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100],
          },
        },
        yaxis: {
          //   min: 20000000,
          //   max: 250000000,
          //   labels: {
          //     formatter(val) {
          //       return (val / 1000000).toFixed(0);
          //     },
          //   },
          title: {
            text: 'Pontos',
          },
        },
        xaxis: {
          type: 'datetime',
        },
        tooltip: {
          shared: false,
          //   y: {
          //     formatter(val) {
          //       return (val / 1000000).toFixed(0);
          //     },
          //   },
        },
      },
    };
  },
};
</script>
